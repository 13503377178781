/**
 * This file kicks off vite tasks to minify and compile CSS and JS.
 */
const flame = String.fromCodePoint(0x1f525)

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log( flame + "HMR")
    });
}

// CSS imports
import './assets/css/brandportal.css'

// JS imports
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import validate from '@colinaut/alpinejs-plugin-simple-validate'
import './assets/js/_obaia-window-helpers'
import './assets/js/brandportal/_obaia-components.js'

Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.plugin(collapse)
Alpine.plugin(validate)
Alpine.start()

window.addEventListener('load', (event) =>{
    document.documentElement.classList.add('is-loaded')
})

console.log(`%c
           
            `+ flame + ` Made with a keyboard by ` + flame + `

 ________      ________      ________      ___      ________     
|\\   __  \\    |\\   __  \\    |\\   __  \\    |\\  \\    |\\   __  \\    
\\ \\  \\|\\  \\   \\ \\  \\|\\ /_   \\ \\  \\|\\  \\   \\ \\  \\   \\ \\  \\|\\  \\   
 \\ \\  \\\\\\  \\   \\ \\   __  \\   \\ \\   __  \\   \\ \\  \\   \\ \\   __  \\  
  \\ \\  \\\\\\  \\   \\ \\  \\|\\  \\   \\ \\  \\ \\  \\   \\ \\  \\   \\ \\  \\ \\  \\ 
   \\ \\_______\\   \\ \\_______\\   \\ \\__\\ \\__\\   \\ \\__\\   \\ \\__\\ \\__\\
    \\|_______|    \\|_______|    \\|__|\\|__|    \\|__|    \\|__|\\|__|


                            WEBDEVELOPMENT
                            https://obaia.nl

`, `font-family: monospace;`)