import Swiper from "swiper"
import {Autoplay, EffectCreative, Mousewheel, Navigation, Pagination} from "swiper/modules"

window.obaia.Components = {}
window.obaia.Components.swiper = (options) => {
    return {
        swiperConstance: '',
        init() {
            let initialSlideIndex = 0
            if (options.initialSlide !== '' && typeof options.initialSlide === 'string') {
                initialSlideIndex = Array.prototype.indexOf.call(this.$el.querySelectorAll('.swiper-slide'), this.$el.querySelector('#' + options.initialSlide))
            }
            const thisElem = this.$el
            window.addEventListener('load', (event) => {
                this.swiperConstance = new Swiper(thisElem, {
                    modules: [Navigation, Pagination, Mousewheel, EffectCreative, Autoplay],
                    autoplay: options.autoplay ?? false,
                    speed: options.speed ?? 300,
                    freeMode: options.freeMode ?? false,
                    loop: options.loop ?? false,
                    autoHeight: options.autoHeight ?? false,
                    slidesPerView: options.slidesPerView ?? 1,
                    centeredSlides: options.centeredSlides ?? false,
                    spaceBetween: options.spaceBetween ?? 16,
                    initialSlide: options.initialSlide ? initialSlideIndex : options.centeredSlides ? this.$el.querySelectorAll('.swiper-slide').length / 2 : 0,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    navigation: {
                        prevEl: this.$refs.swiperPrev ?? thisElem.closest('section').querySelector('.js-swiper-button-prev'),
                        nextEl: this.$refs.swiperNext ?? thisElem.closest('section').querySelector('.js-swiper-button-next'),
                    },
                    breakpoints: {
                        ...options.breakpoints
                    },
                })
                this.swiperConstance.slideTo(0)
            })
        }
    }
}
window.obaia.Components.searchOverlay = (options) => {
    return {
        search: '',
        client: options.client,
        results: [],
        open: false,
        init() {
            // this.$refs.search.focus()
        },
        fetch(searchValue) {
            this.search = searchValue
            if (this.search.length > 2) {
                const data = {q: this.search, client: this.client}
                const url = '/actions/site-module/search'
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': window.obaia.csrf['csrfTokenValue'],
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok')
                        }
                        return response.json()
                    })
                    .then(data => {
                        console.log(data)
                        this.results = data?.results
                        this.loading = false
                    })
                    .catch(error => {
                            console.error(error)
                        }
                    )
                this.open = true
                this.searchScreen = true
            } else {
                this.searchScreen = false
                this.open = false
            }
        },
        isOpen() {
            return this.open && this.results.length > 0
        },
        scrollToSection(url) {
            this.searchScreen = false
            const id = url.split("#")[1]
            const section = document.getElementById(id)
            if (section) {
                setTimeout(() => {
                    section.scrollIntoView({behavior: 'smooth'})
                }, 170);
            } else {
                window.location.href = url
            }
        }
    }
}

window.obaia.Components.popoverGroup = function () {
    return {
        __type: "popoverGroup",
        init() {
            let e = t => {
                    document.body.contains(this.$el) ? t.target instanceof Element && !this.$el.contains(t.target) && window.dispatchEvent(new CustomEvent("close-popover-group", {
                        detail: this.$el
                    })) : window.removeEventListener("focus", e, !0)
                }
            ;
            window.addEventListener("focus", e, !0)
        }
    }
}

window.obaia.Components.popover = function ({open: e = !1, focus: t = !1} = {}) {
    const i = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
    return {
        __type: "popover",
        open: e,
        init() {
            t && this.$watch("open", (e => {
                    e && this.$nextTick((() => {
                            !function (e) {
                                const t = Array.from(e.querySelectorAll(i));
                                !function e(i) {
                                    void 0 !== i && (i.focus({
                                        preventScroll: !0
                                    }),
                                    document.activeElement !== i && e(t[t.indexOf(i) + 1]))
                                }(t[0])
                            }(this.$refs.panel)
                        }
                    ))
                }
            ));
            let e = i => {
                    if (!document.body.contains(this.$el))
                        return void window.removeEventListener("focus", e, !0);
                    let n = t ? this.$refs.panel : this.$el;
                    if (this.open && i.target instanceof Element && !n.contains(i.target)) {
                        let e = this.$el;
                        for (; e.parentNode;)
                            if (e = e.parentNode,
                            e.__x instanceof this.constructor) {
                                if ("popoverGroup" === e.__x.$data.__type)
                                    return;
                                if ("popover" === e.__x.$data.__type)
                                    break
                            }
                        this.open = !1
                    }
                }
            ;
            window.addEventListener("focus", e, !0)
        },
        onEscape() {
            this.open = !1,
            this.restoreEl && this.restoreEl.focus()
        },
        onClosePopoverGroup(e) {
            e.detail.contains(this.$el) && (this.open = !1)
        },
        toggle(e) {
            this.open = !this.open,
                this.open ? this.restoreEl = e.currentTarget : this.restoreEl && this.restoreEl.focus()
        }
    }
}